import { Dialog, DialogContent, IconButton, TableContainer, Tooltip } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { maxWidth, padding, styled } from "@mui/system";
import { getDateDDMM, getDateDDMMYYYY, getDay, getHrMin } from "../../sdk";
import {
  Box,
  DATE,
  DAY,
  MyCard,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "../Styles";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Stack, TextField } from "@mui/material";
import { useState } from "react";
import Draggable from "react-draggable";
import { channelLogs } from "../../svgs/ChannelLogs";
import { ArrowBackIos, ArrowForwardIos, DeleteOutline, Edit, InfoOutlined } from "@mui/icons-material";
import { RemarkButton } from "../../Pace/components/Styles";

const ClassWrapper = styled(Box)(({ theme }) => ({
  ".root": {
    width: "100%",
  },
  ".stickyHeader": {
    position: "sticky",
    top: 0,
  },
  ".container": {
    height: "calc(100vh - 190px)",
  },
  ".arrow": {
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
  ".rowHover": {
    "&:hover": {
      color: "red",
      backgroundColor: "rgba(48, 81, 221,0.4) !important",
    },
  },
}));

function formDataTime(date) {
  const newDate = new Date(date)
  const year = newDate.getFullYear();
  const month = String(newDate.getMonth() + 1).padStart(2, "0");
  const day = String(newDate.getDate()).padStart(2, "0");
  const mints = String(newDate.getMinutes()).padStart(2,"0")
  const hours = String(newDate.getHours()>12?newDate.getHours()-12:newDate.getHours()).padStart(2, "0")
  const timeStamp = newDate.getHours()>12?"PM":"AM"
  const dateString = `${day}/${month}/${year}`;
  const timeString = ` ${hours}:${mints} ${timeStamp}`
  return dateString + timeString;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      style={{ width: "100%", height: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ width: "100%", padding: "0 8px", height: "100%" }}>
          {children}
        </Box>
      )}
    </Box>
  );
}
// just checking
const PricingTable = ({
  array,
  fileId,
  allFilesForDay,
  uploadImage,
  sendRemark,
  selectedImages,
  setSelectedImages,
  remarkData,
  remarksData,
  setRemarksData,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [popOver, setPopOver] = useState(undefined);
  const [noData, setNoData] = useState(false);
  const [today, setToday] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popOverDate, setPopOverDate] = useState("");
  const [disablePrevDate, setDisablePrevDate] = useState(false);
  const [futureRemark, setFutureRemark] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [openImageDialog, setOpenImageDialog] = useState(false);

  const id = !!anchorEl ? "simple-popover" : undefined;

  let previousLength = 0;

  const handleInput = (event) => {
    const bullet = "\u2022";
    const newLength = event.target.value.length;
    const characterCode = event.target.value.substr(-1).charCodeAt(0);

    if (newLength > previousLength) {
      if (characterCode === 10) {
        event.target.value = `${event.target.value}${bullet} `;
      } else if (newLength === 1) {
        event.target.value = `${bullet} ${event.target.value}`;
      }
    }
    previousLength = newLength;
  };

  const convertImageToBlob = async (image) => {
    const response = await fetch(image.uri);
    const blob = await response.blob();
    return blob;
  };

  const handleImagePreview = async (e,idx) => {
    if (e.target.files) {
      const images = [];
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        if (file.type.startsWith("image")) {
          const image = URL.createObjectURL(file);
          const blob = await convertImageToBlob({ uri: image });
          images.push(blob);
        } else {
          alert("Only image files are allowed.");
          break;
        }
      }
      const imageUrls = await uploadImage(images, popOverDate);
      // setSelectedImages((prevState) => [...prevState, ...imageUrl]);
      setPopOver((prevPopOver) => ({
        ...prevPopOver,
        remark: prevPopOver.remark.map((rem, index) =>
          index === idx
            ? {
              ...rem,
              remarkUpload: [
                ...(rem?.remarkUpload || []),
                ...imageUrls.map((img) => ({
                  url: img.imageURL,
                  uploadId: img.id,
                })),
              ],
              isUpdated: true,
            }
            : rem
        ),
      }));
    }
  };

  const handleDeleteImage = (index, deletedImage) => {
    setSelectedImages((prevSelectedImages) =>
      prevSelectedImages.filter((img, i) => i !== index)
    );
    setPopOver((prevPopOver) => ({
      ...prevPopOver,
      remark: prevPopOver.remark.map((rem) =>
        rem.remarkUpload?.some((upload) => upload.url === deletedImage?.url)
          ? {
              ...rem,
              remarkUpload: rem.remarkUpload.filter(
                (val) => val?.url !== deletedImage?.url
              ),
              isUpdated : true
            }
          : rem
      ),
    }));
  };

  const handleClose = () => {
    setAnchorEl(null);
    // setSelectedImages([]);
  };

  const handleDeleteRemark = (indexToDelete) => {
    setPopOver((prevPopOver) => ({
      ...prevPopOver,
      remark: prevPopOver.remark.filter((_, index) => index !== indexToDelete),
    }));
  };

  const handleAddRemark = () => { 
    setPopOver((prevPopOver) => ({
      ...prevPopOver,
      remark: [
        ...(prevPopOver?.remark || []),
        {
          createdBy: "",
          createdAt: "",
          remark: "",
          isEditing: true,
          remarkUpload: [],
          isUpdated :true
        },
      ],
    }));
  };

  
  const handleOpenImageDialog = (images, index) => {
    setSelectedImages(images);
    setCurrentImageIndex(index);
    setOpenImageDialog(true);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === selectedImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? selectedImages.length - 1 : prevIndex - 1
    );
  };
  

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false);
  };

  const handleSaveChanges = () => {
    sendRemark(popOverDate, popOver);
    setAnchorEl(null);
  };

  const handleEditBtn = () => {
    setIsEditing(true);
  };

  const handleDiscard = () => {
    setIsEditing(false);
    const data = remarkData.find((item) => {
      return (
        String(new Date(item.remark.date).toLocaleDateString()) ===
        String(new Date(popOverDate).toLocaleDateString())
      );
    });
    setPopOver(data);
    setSelectedImages([]);
  };

  const handleRowClick = (date, e) => {
    setPopOverDate(date);
    const data = remarksData?.find((item) => {
      return (
        String(new Date(item?.date).toLocaleDateString()) ===
        String(new Date(date)?.toLocaleDateString())
      );
    });

    if (data) {
      setPopOver(data);
      setToday(
        new Date()?.toLocaleDateString() ===
          new Date(date)?.toLocaleDateString()
      );
      setNoData(false);
    } else {
      setPopOver(undefined);
      setNoData(true);
    }
    setAnchorEl(e.currentTarget);
    setDisablePrevDate(
      String(new Date()?.toLocaleDateString()) ===
        String(new Date(date)?.toLocaleDateString())
        ? false
        : new Date() > new Date(date)
    );
    setFutureRemark(new Date() < new Date(date));
  };

  return (
    <ClassWrapper>
      {allFilesForDay.map((file, index) => (
        <TabPanel value={fileId} index={file.id} key={index}>
          {array && array[0] ? (
            <MyCard>
              <TableContainer className="container">
                <Table aria-label="sticky table">
                  <Thead>
                    <Tr className="stickyHeader">
                      <Th
                        style={{
                          width: "40px",
                          background: " #306FBC",
                          color: "#ffffff",
                          borderRadius: "8px 0px 0px 0px",
                          verticalAlign: "middle",
                        }}
                      >
                        Date
                      </Th>
                      <Th
                        style={{
                          width: "40px",
                          background: " #306FBC",
                          color: "#ffffff",
                          verticalAlign: "middle",
                        }}
                      >
                        DOW
                      </Th>
                      <Th
                        style={{
                          width: "60px",
                          maxWidth: "60px",
                          background: " #306FBC",
                          color: "#ffffff",
                          verticalAlign: "middle",
                        }}
                      >
                        Occ%
                      </Th>
                      <Th
                        style={{
                          width: "100px",
                          background: " #306FBC",
                          color: "#ffffff",
                          verticalAlign: "middle",
                        }}
                      >
                        Our Price
                      </Th>
                      {array &&
                        array[0].competitorPrices &&
                        array[0].competitorPrices
                          .sort((a, b) => a.competitorID - b.competitorID)
                          .map((datax) => {
                            return (
                              <Th
                                style={{
                                  background: " #306FBC",
                                  color: "#ffffff",

                                  borderRadius: "0px",
                                  verticalAlign: "middle",
                                }}
                                key={datax.competitorID}
                              >
                                {datax.competitorName}
                              </Th>
                            );
                          })}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {array &&
                      array.map((data, index) => (
                        <Tr key={index}>
                          <Td
                            style={{
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontweight: 500,
                              fontSize: "16px",
                              lineHeight: "20px",
                              color: "#212121",
                              borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                            }}
                          >
                            {(new Date()?.toLocaleDateString() === new Date(data.date)?.toLocaleDateString()) ||
                                remarksData?.some(({ date }) => String(new Date(date).toLocaleDateString()) === String(new Date(data.date)?.toLocaleDateString())) ? 
                                <DATE>
                                <div
                                  onClick={(e) => {
                                    handleRowClick(data.date, e);
                                  }}
                                  style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                                >
                                    {remarksData?.some(({ date }) => String(new Date(date).toLocaleDateString()) === String(new Date(data.date)?.toLocaleDateString())) ?
                                      <InfoOutlined fontSize="8px" style={{ marginLeft: "-14px" }} />
                                      : null}
                                    <Typography fontSize={"14px"}>{getDateDDMM(data.date)}{" "}</Typography>
                                </div>
                              </DATE> : <DATE>
                                  {getDateDDMM(data.date)}{" "}
                              </DATE>}
                          </Td>
                          <Td
                            style={{
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontweight: 500,
                              fontSize: "16px",
                              lineHeight: "20px",
                              color: "#212121",
                            }}
                          >
                            {(new Date()?.toLocaleDateString() === new Date(data.date)?.toLocaleDateString()) ||
                                remarksData?.some(({ date }) => String(new Date(date).toLocaleDateString()) === String(new Date(data.date)?.toLocaleDateString())) ? 
                                <DATE>
                                <DAY>{getDay(data.date)}</DAY>
                              </DATE> : <DATE>
                                <DAY>{getDay(data.date)}</DAY>
                              </DATE>}
                          </Td>
                          <Td
                            style={
                              data?.occupancyColor
                                ? {
                                    background: `linear-gradient(${data.occupancyColor}, #FFFFFF)`,
                                    borderRight: "solid 1px #FFFFFF",
                                    textAlign: "center",
                                    fontFamily: "Roboto",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    color: "#212121",
                                    width: "60px",
                                    maxWidth: "60px",
                                  }
                                : {
                                  width: "60px",
                                  maxWidth: "60px"
                                }
                            }
                          >
                            {data.occupancy === 0 && !data.occupancyColor
                              ? "-"
                              : Math.round(data.occupancy) + "%"}
                          </Td>
                          <Td>
                            <Tooltip followCursor
                              disableHoverListener = {!data.channelId}
                              title={<p>
                                OTA Name: {data.channelName} <br/> 
                                  Updated On: {formDataTime(data.updatedAt)}
                                </p>}
                              placement="top"
                              arrow
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  padding: 0,
                                  paddingBottom: "4px",
                                  paddingTop: "4px"
                                }}
                              >
                                {Object.keys(channelLogs).includes(`${data.channelId}`) ?
                                  <Box style={{
                                    flex: 1,
                                    padding: 0,
                                    justifyContent: "flex-start",
                                    display: "flex"
                                  }}>
                                    <img style={{
                                      height: 20,
                                      width: 20,
                                      padding: 0,
                                      margin: 0
                                    }}
                                      alt={data.channeName}
                                      src={channelLogs[data.channelId]}
                                    />
                                    {data.channelName.includes("mobile") ? <img style={{
                                      height: 20,
                                      width: 20,
                                      padding: 0,
                                      margin: 0
                                    }}
                                      alt={data.channeName}
                                      src={"/assets/mobileIcon.svg"}
                                    /> : null}
                                  </Box>
                                  : null}
                                  <Box
                                    style={{
                                      fontFamily: "Roboto",
                                      fontStyle: "normal",
                                      fontweight: 500,
                                      fontSize: "16px",
                                      lineHeight: "20px",
                                      color: "#212121",
                                      flex: 1,
                                      padding: 0
                                    }}
                                  >
                                    {data?.isSoldOut ? (
                                      <p>Sold Out</p>
                                    ) : data?.Price === -2 ? (
                                      <p>-</p>
                                    ) : (
                                      data?.Price
                                    )}
                                  </Box>
                              </Box>
                            </Tooltip>
                          </Td>

                          {data.competitorPrices &&
                            data.competitorPrices
                              .sort((a, b) => a.competitorID - b.competitorID)
                              .map((datax, idx) =>
                                datax.price === -2 ? (
                                  <Td
                                    key={idx}
                                    style={{
                                      font:
                                        " normal normal 500 16px/20px Roboto",
                                      border: "solid 1px #FFFFFF",
                                      padding: "0px 0px",

                                      background:
                                        "linear-gradient(#757575,#FFFFFF)",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      N/A
                                    </div>
                                  </Td>
                                ) : (
                                  <Td
                                    key={idx}
                                    style={
                                      data.Price < datax.price ||
                                      datax.price === -1
                                        ? {
                                            font:
                                              " normal normal 500 16px/20px Roboto",
                                            border: "solid 1px #FFFFFF",
                                            padding: "0px 0px",

                                            background:
                                              "linear-gradient(#4ABD3C,#FFFFFF)",
                                            borderRadius: "4px",
                                          }
                                        : {
                                            font:
                                              " normal normal 500 16px/20px Roboto",
                                            border: "solid 1px #FFFFFF",
                                            padding: "0px 0px",
                                            color: "#FFF",
                                            background:
                                              "linear-gradient(#D30E08,#FFFFFF)",

                                            borderRadius: "4px",
                                          }
                                    }
                                  >
                                    <Box
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "4px"
                                      }}
                                    >
                                        <Box
                                          width={"100%"}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: "2px",
                                            justifyContent: "center"
                                          }}
                                        >
                                        {datax?.isSoldOut ? (
                                          <p>Sold&nbsp;Out</p>
                                        ) : (
                                          datax.price
                                        )}
                                      </Box>

                                        <Box
                                          style={
                                            datax.priceDifference >= 0
                                              ? {
                                                  background:
                                                    data.Price < datax.price
                                                      ? "linear-gradient(rgb(75,193,60,0.1), rgb(255, 255, 255,1))"
                                                      : "linear-gradient(rgb(75,193,60,0.9), rgb(255, 255, 255,1))",

                                                  height: "42px",
                                                  padding: "0px 0px",

                                                  paddingTop: "2px",

                                                  textAlign: "center",
                                                  width: "100%",
                                                  font:
                                                    " normal normal 500 16px Roboto",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                }
                                              : {
                                                  background: !(
                                                    data.Price < datax.price ||
                                                    datax.price === -1
                                                  )
                                                    ? "linear-gradient(rgb(211,14,8,0.1), rgb(255, 255, 255,1))"
                                                    : "linear-gradient(rgb(211,14,8,0.9), rgb(255, 255, 255,1))",

                                                  height: "42px",
                                                  color: "#FFF",
                                                  textAlign: "center",

                                                  paddingTop: "2px",
                                                  padding: "0px 0px",
                                                  width: "100%",
                                                  font:
                                                    "normal normal 500 16px Roboto",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                }
                                          }
                                        >
                                          <Box
                                            style={{
                                              margin: "auto",
                                            }}
                                          >
                                            {datax.priceDifference}
                                          </Box>
                                        </Box>
                                    </Box>  
                                  </Td>
                                )
                              )}
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </MyCard>
          ) : (
            <Box></Box>
          )}
        </TabPanel>
      ))}
      <Draggable>
        {noData || today ? (
          disablePrevDate ? (
            <>
              <div style={{ cursor: "default" }}></div>
            </>
            // <Popover
            //   id={id}
            //   open={!!anchorEl}
            //   anchorEl={anchorEl}
            //   onClose={handleClose}
            //   anchorOrigin={{
            //     vertical: "bottom",
            //     horizontal: "center",
            //   }}
            //   sx={{
            //     "& .MuiPopover-paper": {
            //       border: "1px solid #000000",
            //       maxWidth: "587px",
            //       maxHeight: "435px",
            //     },
            //   }}
            // >
            //   <Stack direction={"row"} p={2} gap={4}>
            //     <Typography>No Remark</Typography>
            //     <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
            //   </Stack>
            // </Popover>
          ) : futureRemark ? (
            <>
              <div style={{ cursor: "default" }}></div>
            </>
          ) : (
            <Popover
              id={id}
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              sx={{
                "& .MuiPopover-paper": {
                  border: "1px solid #000000",
                  maxWidth: "587px",
                  maxHeight: "550px",
                  padding: "0 8px 16px",
                  minWidth: "500px",
                },
              }}
            >
              <>
                <Stack
                  direction="row"
                  sx={{
                    position: "sticky",
                    top: 0,
                    padding: "16px 8px 8px",
                    background: "white",
                    zIndex: 5,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      flexGrow: 1,
                      textAlign: "center",
                      cursor: "move",
                    }}
                  >
                    Remark of{" "}
                    {getDateDDMMYYYY(
                      popOver?.date ? popOver?.date : popOverDate
                    )}{" "}
                  </Typography>
                  <CloseIcon
                    sx={{ cursor: "pointer", mr: "10px" }}
                    onClick={handleClose}
                  />
                </Stack>

                <Stack gap={1} mb={2} px={2}>
                  {popOver?.remark?.map((item, idx) => (
                    <Stack
                      sx={{
                        border: "1px solid #7474744D",
                        borderRadius: "8px",
                        position: "relative",
                        padding: "8px",
                      }}
                      gap={"10px"}
                      pl={2}
                      pr={"20px"}
                    >
                      {!item?.id && (
                        <DeleteOutline
                          sx={{
                            cursor: "pointer",
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            color: "#f1592b",
                            zIndex: 1
                          }}
                          onClick={() => handleDeleteRemark(idx)}
                        />
                      )}
                      {item?.createdAt && (
                        <Stack direction="row" justifyContent={"space-between"}>
                          <Typography
                            sx={{ fontWeight: "500", fontSize: "14px" }}
                          >
                            {item?.createdBy ?? ""}{" "}
                          </Typography>
                          <Typography
                            sx={{ fontWeight: "500", fontSize: "14px" }}
                          >
                            {getDateDDMMYYYY(popOver?.date)}{" "}
                            {getHrMin(item?.createdAt ?? "")}{" "}
                          </Typography>
                        </Stack>
                      )}
                      <Box style={{
                        padding: "0",
                        display: "flex",
                        alignItems: "center"
                      }}>
                        {!item.isEditing ?
                          <>
                            <Typography sx={{
                              flex: 1
                            }}>
                              {item?.remark}
                            </Typography>
                            <IconButton sx={{ padding: "0" }}
                              onClick={(e) => {
                                const updatedRemarkText = e.target.value;
                                setPopOver((prevPopOver) => ({
                                  ...prevPopOver,
                                  remark: prevPopOver.remark.map((rem, index) =>
                                    index === idx
                                      ? { ...rem, isEditing: true }
                                      : rem
                                  ),
                                }));
                              }}>
                              <Edit />
                            </IconButton>
                          </>
                          :
                          <TextField
                          sx={{
                            flex: 1,
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#000000",
                            },
                          }}
                          slotProps={{
                            input: {
                              readOnly: true,
                            },
                          }}
                          multiline
                          fullWidth
                          value={item?.remark}
                          onChange={(e) => {
                            const updatedRemarkText = e.target.value;
                            setPopOver((prevPopOver) => ({
                              ...prevPopOver,
                              remark: prevPopOver.remark.map((rem, index) =>
                                index === idx
                                  ? { ...rem, remark: updatedRemarkText, isUpdated : true }
                                  : rem
                              ),
                            }));
                          }}
                          variant="standard"
                          placeholder="Type your remark..."
                          InputProps={{ disableUnderline: "true" }}
                          // onInput={handleInput}
                        />}
                      </Box>

                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          flexDirection: "row",
                          gap: "8px",
                        }}
                      >
                        {[
                          ...(!!item?.remarkUpload?.length
                            ? item?.remarkUpload
                            : []),
                        ].map((img, index) => {
                          return (
                            <Box
                              // mt={3}
                              key={index}
                              style={{
                                position: "relative",
                                padding: "0px",
                              }}
                            >
                              {item.isEditing ? <CloseIcon
                                style={{
                                  cursor: "pointer",
                                  position: "absolute",
                                  top: "-4px",
                                  right: "-8px",
                                  color: "white",
                                  backgroundColor: "#6C7270",
                                  borderRadius: "50%",
                                  height: "17px",
                                  width: "18px",
                                }}
                                onClick={() => handleDeleteImage(index, img)}
                              /> : null}

                              <img
                                src={img?.url}
                                alt="Preview"
                                style={{
                                  height: "50px",
                                  width: "50px",
                                  objectFit: "cover",
                                  margin: 1,
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleOpenImageDialog(
                                    item?.remarkUpload,
                                    index
                                  )
                                }
                              />
                            </Box>
                          );
                        })}

                        {item.isEditing ? <label>
                          <Stack
                            direction="row"
                            ml={2}
                            // mt={"4px"}
                            height={"100%"}
                            alignItems={"center"}
                          >
                            <AddPhotoAlternateIcon
                              onClick={(e) => handleImagePreview(e, idx)}
                              sx={{ color: "grey", cursor: "pointer" }}
                            />
                            <Typography
                              sx={{
                                fontWeight: 500,
                                color: "grey",
                                ml: 1,
                                cursor: "pointer",
                              }}
                            >
                              Add Image
                            </Typography>
                            <TextField
                              type="file"
                              InputProps={{
                                disableUnderline: true,
                              }}
                              sx={{ visibility: "hidden", width: "50px" }}
                              variant="standard"
                              onChange={(e) => handleImagePreview(e, idx)}
                              multiple
                            />
                          </Stack>
                        </label>: null}
                      </div>
                    </Stack>
                  ))}
                  <RemarkButton onClick={handleAddRemark}>
                    + Add Remark
                  </RemarkButton>
                </Stack>
                <Stack
                  px={2}
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    disabled = {(popOver?.remark??[]).filter(({isEditing})=>isEditing).length===0 || (popOver?.remark??[]).some(({isEditing, remark, remarkUpload})=>(isEditing && remark===""&&remarkUpload.length===0))}
                    onClick={handleSaveChanges}
                    sx={{
                      borderRadius: 2,
                      textTransform: "none",
                      height: 40,
                      fontSize: "14px",
                    }}
                  >
                    Save Changes
                  </Button>
                </Stack>
              </>
            </Popover>
          )
        ) : (
          <Popover
            id={id}
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            sx={{
              "& .MuiPopover-paper": {
                border: "1px solid #000000",
                maxWidth: "587px",
                maxHeight: "550px",
                padding: "0 8px 16px",
                minWidth: "500px",
              },
            }}
          >
            <>
              <Stack
                sx={{
                  position: "sticky",
                  top: 0,
                  padding: "16px 8px 8px",
                  background: "white",
                  zIndex: 5,
                }}
                direction="row"
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    flexGrow: 1,
                    textAlign: "center",
                    cursor: "move",
                  }}
                >
                  Remark of{" "}
                  {getDateDDMMYYYY(popOver?.date ? popOver?.date : popOverDate)}{" "}
                </Typography>
                <CloseIcon
                  sx={{ cursor: "pointer", mr: "10px" }}
                  onClick={handleClose}
                />
              </Stack>

              <Stack gap={1} mb={2} px={2}>
                {popOver?.remark?.map((item, idx) => (
                  <Stack
                    sx={{ border: "1px solid #7474744D", borderRadius: "8px",
                        padding: "8px" }}
                    gap={1}
                    px={2}
                  >
                    <Stack direction="row" justifyContent={"space-between"}>
                      <Typography sx={{ fontWeight: "500", fontSize: "14px" }}>
                        {item?.createdBy ?? ""}{" "}
                      </Typography>
                      <Typography sx={{ fontWeight: "500", fontSize: "14px" }}>
                        {getDateDDMMYYYY(popOver?.date)}{" "}
                        {getHrMin(item?.createdAt ?? "")}{" "}
                      </Typography>
                    </Stack>
                    <TextField
                      disabled={true}
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#000000",
                        },
                      }}
                      multiline
                      fullWidth
                      value={item?.remark}
                      onChange={(e) => {
                        const updatedRemarkText = e.target.value;
                        setPopOver((prevPopOver) => ({
                          ...prevPopOver,
                          remark: prevPopOver.remark.map((rem, index) =>
                            index === idx
                              ? { ...rem, remark: updatedRemarkText , isUpdated: true}
                              : rem
                          ),
                        }));
                      }}
                      variant="standard"
                      placeholder="Type your remark..."
                      InputProps={{ disableUnderline: "true" }}
                      // onInput={handleInput}
                    />

                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "row",
                        gap: "8px",
                      }}
                    >
                      {[
                        ...(!!item?.remarkUpload?.length
                          ? item?.remarkUpload
                          : []),
                      ].map((img, index) => {
                        console.log({ img });
                        return (
                          <Box
                            // mt={3}
                            key={index}
                            style={{
                              position: "relative",
                              padding: "0px",
                            }}
                          >
                            {/* {isEditing && (
                              <CloseIcon
                                style={{
                                  cursor: "pointer",
                                  position: "absolute",
                                  top: "-4px",
                                  right: "-8px",
                                  color: "white",
                                  backgroundColor: "#6C7270",
                                  borderRadius: "50%",
                                  height: "17px",
                                  width: "18px",
                                }}
                                onClick={() => handleDeleteImage(index, img)}
                              />
                            )} */}

                            <img
                              src={img?.url}
                              alt="Preview"
                              style={{
                                height: "50px",
                                width: "50px",
                                objectFit: "cover",
                                margin: 1,
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleOpenImageDialog(item?.remarkUpload, index)
                              }
                            />
                          </Box>
                        );
                      })}
                      {/* {isEditing && (
                        <label>
                          <Stack
                            direction="row"
                            ml={2}
                            // mt={"4px"}
                            height={"100%"}
                            alignItems={"center"}
                          >
                            <AddPhotoAlternateIcon
                              onClick={(e) => handleImagePreview(e, idx)}
                              sx={{ color: "grey", cursor: "pointer" }}
                            />
                            <Typography
                              sx={{
                                fontWeight: 500,
                                color: "grey",
                                ml: 1,
                                cursor: "pointer",
                              }}
                            >
                              Add Image
                            </Typography>
                            <TextField
                              type="file"
                              InputProps={{
                                disableUnderline: true,
                              }}
                              sx={{ visibility: "hidden", width: "50px" }}
                              variant="standard"
                              onChange={(e) => handleImagePreview(e, idx)}
                              multiple
                            />
                          </Stack>
                        </label>
                      )} */}
                    </div>
                  </Stack>
                ))}
              </Stack>
            </>
          </Popover>
        )}
      </Draggable>
      {/* <Draggable>
        {noData ? (
          disablePrevDate ? (
            <Popover
              id={id}
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              sx={{
                "& .MuiPopover-paper": {
                  border: "1px solid #000000",
                  maxWidth: "587px",
                  maxHeight: "435px",
                },
              }}
            >
              <Stack direction={"row"} p={2} gap={4}>
                <Typography>No Remark</Typography>
                <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
              </Stack>
            </Popover>
          ) : futureRemark ? (
            <>
              <div style={{ cursor: "default" }}></div>
            </>
          ) : (
            <Popover
              id={id}
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              sx={{
                "& .MuiPopover-paper": {
                  border: "1px solid #000000",
                  maxWidth: "587px",
                  maxHeight: "435px",
                },
              }}
            >
              <>
                <Stack direction="row" mt={3}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      flexGrow: 1,
                      textAlign: "center",
                      cursor: "move",
                    }}
                  >
                    Remark of{" "}
                    {getDateDDMMYYYY(popOver?.remark?.date ?? popOverDate)}{" "}
                  </Typography>
                  <CloseIcon
                    sx={{ cursor: "pointer", mr: "10px" }}
                    onClick={handleClose}
                  />
                </Stack>

                <Stack direction="row" gap={"141px"} mt={3} ml={3} mr={3}>
                  <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
                    Last Edited By: {popOver?.userName}
                  </Typography>
                  <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
                    Last edited Time:{" "}
                  </Typography>
                </Stack>

                <TextField
                  sx={{ ml: 3, mt: 2, mr: 3 }}
                  multiline
                  fullWidth
                  value={popOver?.remark?.remark}
                  onChange={(e) => {
                    setPopOver({
                      ...popOver,
                      remark: { ...popOver?.remark, remark: e.target.value },
                    });
                  }}
                  variant="standard"
                  placeholder="Type your remark..."
                  InputProps={{ disableUnderline: "true" }}
                  onInput={handleInput}
                />

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    padding: "1px",
                  }}
                >
                  {selectedImages?.map((img, index) => (
                    <Box
                      mt={3}
                      key={index}
                      style={{
                        position: "relative",
                        height: "100px",
                        width: "100px",
                        marginLeft: "20px",
                      }}
                    >
                      <CloseIcon
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          top: "7px",
                          right: "-17px",
                          color: "white",
                          backgroundColor: "#6C7270",
                          borderRadius: "50%",
                          height: "17px",
                          width: "18px",
                        }}
                        onClick={() => handleDeleteImage(index)}
                      />
                      <img
                        src={img.imageURL}
                        alt="Preview"
                        style={{
                          height: "100px",
                          width: "100px",
                          objectFit: "cover",
                          margin: 1,
                        }}
                      />
                    </Box>
                  ))}
                </div>

                <Stack direction="row" style={{ display: "flex" }} mt={7} p={2}>
                  <label>
                    <TextField
                      type="file"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      sx={{ visibility: "hidden" }}
                      variant="standard"
                      onChange={handleImagePreview}
                      multiple
                    />
                    <Stack direction="row">
                      <AddPhotoAlternateIcon
                        onClick={handleImagePreview}
                        sx={{ color: "grey", cursor: "pointer" }}
                      />
                      <Typography
                        sx={{
                          fontWeight: 500,
                          color: "grey",
                          ml: 1,
                          cursor: "pointer",
                        }}
                      >
                        Add Image
                      </Typography>
                    </Stack>
                  </label>
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSaveChanges}
                      sx={{
                        mt: 4,
                        borderRadius: 2,
                        textTransform: "none",
                        height: 40,
                        alignSelf: "end",
                        fontSize: "16px",
                      }}
                    >
                      Save Changes
                    </Button>
                  </>
                </Stack>
              </>
            </Popover>
          )
        ) : (
          <Popover
            id={id}
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            sx={{
              "& .MuiPopover-paper": {
                border: "1px solid #000000",
                maxWidth: "587px",
                maxHeight: "712px",
                p: 2,
              },
            }}
          >
            <>
              <Stack direction="row" mt={1}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    flexGrow: 1,
                    textAlign: "center",
                    cursor: "move",
                  }}
                >
                  Remark of {getDateDDMMYYYY(popOver?.remark?.date)}{" "}
                </Typography>
                <CloseIcon
                  sx={{ cursor: "pointer", mr: "10px" }}
                  onClick={handleClose}
                />
              </Stack>

              <Stack
                direction="row"
                mt={3}
                ml={1}
                mr={1}
                gap={"141px"}
                justifyContent={"space-between"}
              >
                <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
                  Last Edited By: {popOver?.userName ?? ""}{" "}
                </Typography>
                <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
                  Last edited Time: {getHrMin(popOver?.remark?.createdAt ?? "")}{" "}
                </Typography>
              </Stack>

              <TextField
                disabled={!isEditing}
                sx={{
                  ml: 1,
                  mt: 2,
                  mr: 1,
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
                multiline
                fullWidth
                value={
                  popOver?.remark?.remark === "" ? " " : popOver?.remark?.remark
                }
                onChange={(e) => {
                  setIsEditing(true);
                  setPopOver({
                    ...popOver,
                    remark: { ...popOver?.remark, remark: e.target.value },
                  });
                }}
                variant="standard"
                placeholder="Type your remark..."
                InputProps={{ disableUnderline: "true" }}
                onInput={handleInput}
              />

              {isEditing ? (
                <label>
                  <TextField
                    type="file"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    sx={{ visibility: "hidden" }}
                    variant="standard"
                    onChange={handleImagePreview}
                    multiple
                  />
                  <Stack direction="row" justifyContent={"end"}>
                    <AddPhotoAlternateIcon
                      onClick={handleImagePreview}
                      sx={{ color: "grey", cursor: "pointer" }}
                    />
                    <Typography
                      sx={{
                        fontWeight: 500,
                        color: "grey",
                        ml: 1,
                        cursor: "pointer",
                      }}
                    >
                      Add Image
                    </Typography>
                  </Stack>
                </label>
              ) : (
                ""
              )}

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  padding: "1px",
                }}
              >
                {[...(!!popOver?.images?.length ? popOver?.images : [])].map(
                  (img, index) => (
                    <Box
                      mt={3}
                      key={index}
                      style={{
                        position: "relative",
                        height: "260px",
                        width: "500px",
                        // marginLeft: "20px",
                      }}
                    >
                      {isEditing ? (
                        <CloseIcon
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            top: "7px",
                            right: "-17px",
                            color: "white",
                            backgroundColor: "#6C7270",
                            borderRadius: "50%",
                            height: "17px",
                            width: "18px",
                          }}
                          onClick={() => handleDeleteImage(index, img)}
                        />
                      ) : (
                        ""
                      )}
                      <img
                        src={img.imageURL}
                        alt="Preview"
                        style={{
                          height: "258px",
                          width: isEditing ? "500px" : "480px",
                          objectFit: "cover",
                          margin: 1,
                        }}
                      />
                    </Box>
                  )
                )}
              </div>

              {isEditing ? (
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDiscard}
                    sx={{
                      mt: 4,
                      borderRadius: 2,
                      textTransform: "none",
                      height: 40,
                      fontSize: "16px",
                    }}
                  >
                    Discard
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveChanges}
                    sx={{
                      mt: 4,
                      borderRadius: 2,
                      textTransform: "none",
                      height: 40,
                      fontSize: "16px",
                    }}
                  >
                    Save Changes
                  </Button>
                </Stack>
              ) : (
                !disablePrevDate && (
                  <Stack direction="row" mt={7} sx={{ justifyContent: "end" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleEditBtn}
                      sx={{
                        mt: 4,
                        borderRadius: 2,
                        textTransform: "none",
                        height: 40,
                        fontSize: "16px",
                      }}
                    >
                      Edit
                    </Button>
                  </Stack>
                )
              )}
            </>
          </Popover>
        )}
      </Draggable> */}
      
      <Dialog
        open={openImageDialog}
        onClose={handleCloseImageDialog}
        fullWidth
        height="400px"
        maxWidth="sm"
      >
        <DialogContent sx={{ position: "relative" }}>
          <IconButton
            sx={{ position: "absolute", top: 8, right: 8 }}
            onClick={handleCloseImageDialog}
          >
            <CloseIcon />
          </IconButton>

          <Stack direction="row" alignItems="center" justifyContent="center">
            <IconButton onClick={handlePrevImage}>
              <ArrowBackIos />
            </IconButton>

            <Box
              component="img"
              src={selectedImages[currentImageIndex]?.url}
              alt="Selected Preview"
              sx={{
                height: "300px",
                width: "500px",
                objectFit: "contain",
                borderRadius: 1,
              }}
            />

            <IconButton onClick={handleNextImage}>
              <ArrowForwardIos />
            </IconButton>
          </Stack>

          <Typography variant="caption" display="block" align="center" mt={2}>
            {currentImageIndex + 1} / {selectedImages.length}
          </Typography>
        </DialogContent>
      </Dialog>
    </ClassWrapper>
  );
};
export default PricingTable;
